@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next Ultra Light Italic'), local('AvenirNext-UltraLightItalic'),
    url('AvenirNext-UltraLightItalic.woff2') format('woff2'),
    url('AvenirNext-UltraLightItalic.woff') format('woff'),
    url('AvenirNext-UltraLightItalic.svg#AvenirNext-UltraLightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next Medium Italic'), local('AvenirNext-MediumItalic'),
    url('AvenirNext-MediumItalic.woff2') format('woff2'),
    url('AvenirNext-MediumItalic.woff') format('woff'),
    url('AvenirNext-MediumItalic.svg#AvenirNext-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next Bold'), local('AvenirNext-Bold'),
    url('AvenirNext-Bold.woff2') format('woff2'),
    url('AvenirNext-Bold.woff') format('woff'),
    url('AvenirNext-Bold.svg#AvenirNext-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next Ultra Light'),
    url('AvenirNext-UltraLight.woff2') format('woff2'),
    url('AvenirNext-UltraLight.woff') format('woff'),
    url('AvenirNext-UltraLight.svg#AvenirNext-UltraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next Regular'), local('AvenirNext-Regular'),
    url('AvenirNext-Regular.woff2') format('woff2'),
    url('AvenirNext-Regular.woff') format('woff'),
    url('AvenirNext-Regular.svg#AvenirNext-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next Heavy'), local('AvenirNext-Heavy'),
    url('AvenirNext-Heavy.woff2') format('woff2'),
    url('AvenirNext-Heavy.woff') format('woff'),
    url('AvenirNext-Heavy.svg#AvenirNext-Heavy') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next Medium'), local('AvenirNext-Medium'),
    url('AvenirNext-Medium.woff2') format('woff2'),
    url('AvenirNext-Medium.woff') format('woff'),
    url('AvenirNext-Medium.svg#AvenirNext-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next Heavy Italic'), local('AvenirNext-HeavyItalic'),
    url('AvenirNext-HeavyItalic.woff2') format('woff2'),
    url('AvenirNext-HeavyItalic.woff') format('woff'),
    url('AvenirNext-HeavyItalic.svg#AvenirNext-HeavyItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next Demi Bold Italic'), local('AvenirNext-DemiBoldItalic'),
    url('AvenirNext-DemiBoldItalic.woff2') format('woff2'),
    url('AvenirNext-DemiBoldItalic.woff') format('woff'),
    url('AvenirNext-DemiBoldItalic.svg#AvenirNext-DemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next Italic'), local('AvenirNext-Italic'),
    url('AvenirNext-Italic.woff2') format('woff2'),
    url('AvenirNext-Italic.woff') format('woff'),
    url('AvenirNext-Italic.svg#AvenirNext-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next Demi Bold'), local('AvenirNext-DemiBold'),
    url('AvenirNext-DemiBold.woff2') format('woff2'),
    url('AvenirNext-DemiBold.woff') format('woff'),
    url('AvenirNext-DemiBold.svg#AvenirNext-DemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next Bold Italic'), local('AvenirNext-BoldItalic'),
    url('AvenirNext-BoldItalic.woff2') format('woff2'),
    url('AvenirNext-BoldItalic.woff') format('woff'),
    url('AvenirNext-BoldItalic.svg#AvenirNext-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

